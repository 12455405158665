<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>新闻分类</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>名称:</span>
      <el-input
        v-model.trim="list.cateName"
        placeholder="请输入名称"
      ></el-input>
      <el-button type="primary" @click="tableList()">查找</el-button>
      <el-button type="primary" @click="$router.push('/newsCateAdd')"
        >添加</el-button
      >
      <!-- <el-button type="primary" @click="$router.push(`/addCoupon/0`)"
      v-if=" $store.state.powerList.indexOf('coupon:info:list:add') !== -1"
        >添加</el-button
      > -->
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="formData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateId"
        label="id"
      ></el-table-column>
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput(row.dept)"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="分类名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="更新时间"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="goEdit(row.cateId, row.cateName)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onChangeStatus(row.cateId, 2)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import {
  newsCateList,
  newsCateDel,
  newsCateModify,
  newsCateAll,
} from "../../api/news";
export default {
  name: "couponList",
  data() {
    return {
      cateList: [],
      formData: [],
      pagination: {},
      ids: "",
      list: {
        currentPage: 1,
        pageSize: 5,
        cateName: "",
      },
    };
  },
  created() {
    this.tableList();
    this.getCateList();
  },
  methods: {
    async getCateList() {
      const { data } = await newsCateAll();
      this.cateList = data.data;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    goEdit(id, cateName) {
      console.log(id, "id");
      this.$router.push(`/newsCateAdd?id=${id}&cateName=${cateName}`);
    },
    async tableList() {
      const { data } = await newsCateList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.formData = data.list;
      this.pagination = data.pagination;
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async handleInputConfirm(row) {
      const { data } = await newsCateModify({
        cateId: row.cateId,
        dept: row.dept,
      });
      row.inputVisible = false;
    },
    onChangeStatus(id) {
      this.$confirm(`是否要删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await newsCateDel({
            cateId: id,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: 0,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.tableList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.tableList();
    },
  },
};
</script>
<style lang="less" scoped>
.couponList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 300px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
}
</style>